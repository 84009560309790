import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy,
  ViewChildren,
  QueryList,
  ContentChild,
  TemplateRef,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTabGroup } from '@angular/material/tabs';

import { EMPTY, Subscription, of, throwError } from 'rxjs';
import { catchError, delay, finalize, switchMap, tap } from 'rxjs/operators';

import { EditorResult } from '../core/models/dynamicEditor.interface';
import { ModalType } from '../core/models/componentContract.model';
import {
  AttributeResource,
  BroadcastEvent,
  PopupButtonController,
  PopupConfig,
  Resource,
  StatusConfig,
  TabViewEvent,
} from '../core/models/dataContract.model';

import { ModalService } from '../core/services/modal.service';
import { SwapService } from '../core/services/swap.service';
import { ResourceService } from '../core/services/resource.service';

import { AttributeViewComponent } from '../core/components/attribute-view/attribute-view.component';
import {
  AddtionalTab1Directive,
  AddtionalTab2Directive,
} from './tab-view.directive';
import { ConfigService } from '../core/services/config.service';
import { UtilsService } from '../core/services/utils.service';
import { TransService } from '../core/models/translation.model';

import { ResourcePreviewComponent } from '../core/components/activities/resource-preview/resource-preview.component';
import { WindowCloseResult } from '@progress/kendo-angular-dialog';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '../core/components/modal/modal.component';
import { SplitButtonComponent } from '@progress/kendo-angular-buttons';

@Component({
  selector: 'app-tab-view',
  templateUrl: './tab-view.component.html',
  styleUrls: ['./tab-view.component.scss'],
})
export class TabViewComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  private subscription: Subscription = new Subscription();

  @ViewChild('mtg') tabGroup: MatTabGroup;

  @ViewChild('resourcePreview') resourcePreview: ResourcePreviewComponent;

  @ViewChild('previewButton') previewButton: SplitButtonComponent;

  @ViewChildren('attributeView')
  attributeViews: QueryList<AttributeViewComponent>;

  @ContentChild(AddtionalTab1Directive, { static: true })
  addtionalTab1Dir: AddtionalTab1Directive;

  @ContentChild(AddtionalTab2Directive, { static: true })
  addtionalTab2Dir: AddtionalTab2Directive;

  saveDisabled = false;

  tabDefsToShow: Array<any>;

  advancedView = false;
  advancedViewEnabled = false;
  advancedResults: Array<EditorResult> = [];
  advancedAttributeConfig: Array<any> = [];

  tabsDefinition: Array<any>;
  @Input()
  get tabDefs() {
    return this.tabsDefinition;
  }
  set tabDefs(value) {
    this.tabsDefinition = value;
    this.tabDefsToShow = this.getDisplayTabs();
    this.tabDefsChange.emit(this.tabsDefinition);
  }
  @Output()
  tabDefsChange = new EventEmitter();

  @Input()
  formName: string;

  @Input()
  icon: string;

  @Input()
  configMode = false;

  @Input()
  showTitle = true;

  @Input()
  addtionalTab1Name: string;

  @Input()
  addtionalTab2Name: string;

  @Input()
  addtionalTabAttributes: Array<string> = ['DisplayName'];

  @Input()
  statuses: Array<StatusConfig> = [];

  results: { [key: string]: Array<EditorResult> } = {};
  @Input()
  get editorResults() {
    return this.results;
  }
  set editorResults(value) {
    this.results = value;
    this.editorResultsChange.emit(this.results);
  }
  @Output()
  editorResultsChange = new EventEmitter();

  @Output()
  tabIndexChange: EventEmitter<number> = new EventEmitter();

  get addtionalTab1Tpl(): TemplateRef<any> {
    return this.addtionalTab1Dir && this.addtionalTab1Dir.tpl;
  }

  get addtionalTab2Tpl(): TemplateRef<any> {
    return this.addtionalTab2Dir && this.addtionalTab2Dir.tpl;
  }

  get addtionalTabTplContext(): any {
    return { $implicit: this.currentResource };
  }

  get isAdmin(): boolean {
    return this.resource.loginUserIsAdmin();
  }

  currentTabIndex = 0;

  currentResource: Resource;
  currentResourceID: string;

  previewButtonData: Array<any> = [
    {
      name: 'preview',
      text: 'key_preview',
      icon: 'preview',
    },
  ];
  previewAllowed = false;
  previewMode = false;
  previewEvent: BroadcastEvent;
  previewResourceReadOnly = true;

  readOnlyFlag = '';

  isInSideView = false;

  status: StatusConfig;

  typeName: string;

  constructor(
    private modal: ModalService,
    private swap: SwapService,
    private route: ActivatedRoute,
    private router: Router,
    private resource: ResourceService,
    private utils: UtilsService,
    private config: ConfigService,
    private translate: TransService,
    private clipboard: ClipboardService,
    private snackbar: MatSnackBar
  ) {}

  private handelTabChange(stopEvent: boolean, argum: any) {
    return (
      stopEvent &&
      MatTabGroup.prototype._handleClick.apply(this.tabGroup, argum)
    );
  }

  private interceptTabChange(...args) {
    if (this.isTabDirty() && !this.configMode) {
      const argum = args;
      const confirm = this.modal.show(
        ModalType.confirm,
        'key_confirmation',
        'l10n_changesNotSaved'
      );
      this.subscription.add(
        confirm.afterClosed().subscribe((result) => {
          if (result && result === 'yes') {
            this.handelTabChange(true, argum);
          } else {
            this.handelTabChange(false, argum);
          }
        })
      );
    } else {
      return (
        true && MatTabGroup.prototype._handleClick.apply(this.tabGroup, args)
      );
    }
  }

  private inDeniedList(list: string[]) {
    if (this.resource.rightSets && this.resource.rightSets.length > 0) {
      if (list && list.length > 0) {
        for (const deniedSet of list) {
          if (this.resource.rightSets.indexOf(deniedSet) >= 0) {
            return true;
          }
        }
      }
    }
    return false;
  }

  private inAllowedList(list: string[]) {
    if (this.resource.rightSets && this.resource.rightSets.length > 0) {
      if (list && list.length > 0) {
        for (const deniedSet of list) {
          if (this.resource.rightSets.indexOf(deniedSet) >= 0) {
            return true;
          }
        }
      } else {
        return true;
      }
    }
    return false;
  }

  private getDisplayTabs(): Array<any> {
    let result: Array<any> = this.utils.DeepCopy(this.tabDefs);
    result = result.filter((t) => {
      const accessAllowed = t.accessAllowed as Array<string>;
      const accessDenied = t.accessDenied as Array<string>;
      if (!this.inDeniedList(accessDenied)) {
        if (this.inAllowedList(accessAllowed)) {
          return t;
        }
      }
    });
    return result;
  }

  private getAdditionalAttributes() {
    if (!this.statuses) {
      this.statuses = [];
    }
    this.addtionalTabAttributes = this.addtionalTabAttributes.concat(
      this.statuses.map((status: StatusConfig) => {
        if (status.attributeName) {
          return status.attributeName;
        }
      })
    );
    this.addtionalTabAttributes = this.addtionalTabAttributes.filter(
      (item, index) => this.addtionalTabAttributes.indexOf(item) === index
    );

    if (this.addtionalTabAttributes.length === 0) {
      this.addtionalTabAttributes = ['DisplayName'];
    }
  }

  private setStatus() {
    this.status = undefined;
    setTimeout(() => {
      if (this.currentResource && this.statuses) {
        for (const status of this.statuses) {
          const value = this.utils.ExtraValue(
            this.currentResource,
            status.attributeName
          );
          if (value !== undefined && value !== null) {
            const strValue = String(value);
            if (strValue && strValue === status.attributeValue) {
              this.status = status;
              break;
            }
          }
        }
      }
    });
  }

  private refreshComponent() {
    this.saveDisabled = false;
    this.swap.broadcast({
      name: 'refresh-attribute',
      parameter: this.tabDefsToShow[this.currentTabIndex].name,
    });
  }

  private switchView() {
    if (!this.advancedView) {
      const currentObjectType = this.utils.ExtraValue(
        this.currentResource,
        'ObjectType:value'
      );
      const typeSchema = this.utils.ExtraValue(
        this.resource.schema,
        `${currentObjectType}:bindings`
      );
      if (currentObjectType) {
        this.subscription.add(
          of(typeSchema)
            .pipe(
              switchMap((schema) => {
                if (schema) {
                  return of(schema);
                } else {
                  return this.resource.getType(
                    currentObjectType,
                    this.config.getCulture(this.translate.currentCulture)
                  );
                }
              }),
              tap((result) => {
                if (result) {
                  this.advancedAttributeConfig =
                    this.utils.prepareAdvancedViewAttributes(
                      result,
                      this.utils.isAttributeAllowed,
                      this.utils.isAttributeReadonly,
                      false,
                      this.currentResourceID
                    );

                  this.advancedView = true;
                }
              })
            )
            .subscribe()
        );
      }
    } else {
      this.advancedView = false;
    }
  }

  isTabDirty() {
    if (this.advancedView) {
      if (this.advancedResults && this.advancedResults.length > 0) {
        return (
          this.advancedResults.findIndex((t) => t.controller.dirty === true) >=
          0
        );
      }
    } else {
      if (this.tabDefsToShow[this.currentTabIndex]) {
        const tabName = this.tabDefsToShow[this.currentTabIndex].name;
        if (
          this.editorResults[tabName] &&
          this.editorResults[tabName].length > 0
        ) {
          return (
            this.editorResults[tabName].findIndex(
              (t) =>
                t.controller.dirty === true &&
                !t.config.attributeName.endsWith('#')
            ) >= 0
          );
        }
      }
    }

    return false;
  }

  getEditor(attributeName: string, tabName?: string) {
    if (this.attributeViews) {
      if (tabName) {
        const attrView = this.attributeViews.find(
          (a) => a.tabName && a.tabName.toLowerCase() === tabName.toLowerCase()
        );
        if (attrView) {
          return attrView.getEditor(attributeName);
        }
      } else {
        for (const view of this.attributeViews) {
          const editor = view.getEditor(attributeName);
          if (editor) {
            return editor;
          }
        }
      }
    }

    return null;
  }

  getCurrentTabIndex() {
    return this.currentTabIndex;
  }

  getCurrentTabName() {
    return this.tabDefsToShow[this.currentTabIndex].name;
  }

  hasError() {
    if (this.advancedView) {
      if (this.advancedResults && this.advancedResults.length > 0) {
        return (
          this.advancedResults.findIndex((t) => {
            return t.controller.valid === false;
          }) >= 0
        );
      }
    } else {
      const tabName = this.tabDefsToShow[this.currentTabIndex].name;
      if (
        this.editorResults[tabName] &&
        this.editorResults[tabName].length > 0
      ) {
        return (
          this.editorResults[tabName].findIndex((t) => {
            if (this.attributeViews) {
              const editor = this.getEditor(t.attribute.systemName, tabName);
              return t.controller.valid === false && editor;
            } else {
              return t.controller.valid === false;
            }
          }) >= 0
        );
      }
    }

    return false;
  }

  hideTabs(tabNames: Array<string>) {
    this.tabDefsToShow.splice(0, this.tabDefsToShow.length);
    const displayTabs = this.getDisplayTabs();
    displayTabs.forEach((d) => {
      if (!tabNames) {
        this.tabDefsToShow.push(d);
      } else if (tabNames.indexOf(d.name) < 0) {
        this.tabDefsToShow.push(d);
      }
    });
  }

  ngOnInit() {
    this.saveDisabled = false;

    this.tabDefsToShow = this.getDisplayTabs();

    this.isInSideView = this.router.url.indexOf('//sidenav:') > 0;

    this.previewResourceReadOnly = this.config.getConfig(
      'previewResourceReadOnly',
      true
    );

    const advancedViewPermission: string[] = this.config.getConfig(
      'advancedViewPermission',
      []
    );
    this.advancedViewEnabled = this.resource.rightSets.some(
      (r) => advancedViewPermission.indexOf(r) >= 0
    );

    this.previewAllowed = this.resource.rightSets.some(
      (r) => this.config.getConfig('previewPermission', []).indexOf(r) >= 0
    );

    this.subscription.add(
      this.clipboard.copyResponse$.subscribe((res: IClipboardResponse) => {
        if (res.isSuccess) {
          this.snackbar.open(this.translate.instant('key_textCopied'), 'OK', {
            duration: 2000,
          });
        }
      })
    );

    this.subscription.add(
      this.route.queryParams.subscribe((params) => {
        this.readOnlyFlag = params.readonly;
      })
    );

    this.subscription.add(
      this.route.params
        .pipe(
          delay(this.config.getConfig('intervalSmall', 300)),
          switchMap(() => {
            this.previewMode = false;
            this.advancedView = false;

            this.getAdditionalAttributes();
            this.currentResourceID = this.route.snapshot.paramMap.get('id');
            const selfRefresh = this.route.snapshot.paramMap.get('selfRefresh');
            return this.resource
              .getResourceByID(
                this.currentResourceID,
                this.addtionalTabAttributes
              )
              .pipe(
                tap((resource: Resource) => {
                  this.currentResource = resource;
                  this.typeName = this.resource.getTypeDisplayName(
                    this.utils.ExtraValue(this.currentResource, 'ObjectType')
                  );
                  this.setStatus();
                  if (!selfRefresh) {
                    this.swap.tabViewEvent(new TabViewEvent('afterInit', this));
                  }
                })
              );
          })
        )
        .subscribe(
          () => {},
          (error: any) => {
            console.log(error);
            this.router.navigate(['/app/error']);
          }
        )
    );

    this.subscription.add(
      this.swap.broadcasted
        .pipe(
          switchMap((event: BroadcastEvent) => {
            if (event) {
              switch (event.name) {
                case 'enable-save':
                  this.saveDisabled = false;
                  break;
                case 'disable-save':
                  this.saveDisabled = true;
                  break;
                case 'preview-resource':
                  this.previewEvent = event;
                  return this.previewMode
                    ? this.resource.startSimulation()
                    : EMPTY;
                case 'close-advancedView':
                  this.advancedView = false;
                  break;
                default:
                  break;
              }
            }
            return EMPTY;
          }),
          tap((simulationID: string) => {
            if (simulationID) {
              if (this.resourcePreview) {
                this.resourcePreview.resourceReadOnly =
                  this.previewResourceReadOnly;
                this.resourcePreview.simulationID = simulationID;
                this.resourcePreview.action = this.resource.updateResource(
                  this.previewEvent.parameter,
                  false,
                  simulationID
                );
                this.resourcePreview.start();
              }
            }
          })
        )
        .subscribe()
    );

    // jump to the first tab
    this.route.params.subscribe(() => {
      const currentID = this.utils.ExtraValue(
        this.currentResource,
        'ObjectID:value'
      );
      if (currentID !== this.route.snapshot.paramMap.get('id')) {
        setTimeout(() => {
          this.currentTabIndex = 0;
        }, this.config.getConfig('intervalSmall', 300));
      }
    });
  }

  ngAfterViewInit() {
    if (this.tabGroup) {
      this.tabGroup._handleClick = this.interceptTabChange.bind(this);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.configMode) {
      this.tabDefsToShow = this.getDisplayTabs();
      if (this.currentResourceID) {
        this.subscription.add(
          this.resource
            .getResourceByID(
              this.currentResourceID,
              this.addtionalTabAttributes
            )
            .pipe(
              tap((resource: Resource) => {
                this.currentResource = resource;
                this.swap.tabViewEvent(new TabViewEvent('afterInit', this));
              })
            )
            .subscribe()
        );
      }
    } else {
      if (changes.addtionalTabAttributes) {
        if (this.currentResourceID) {
          this.subscription.add(
            this.resource
              .getResourceByID(
                this.currentResourceID,
                this.addtionalTabAttributes
              )
              .pipe(
                tap((resource: Resource) => {
                  this.currentResource = resource;
                  this.swap.tabViewEvent(new TabViewEvent('afterInit', this));
                })
              )
              .subscribe()
          );
        }
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onTabIndexChange(event: number) {
    this.currentTabIndex = event;
    this.tabIndexChange.emit(event);
  }

  onArrange() {}

  // onAddEditor() {
  //   const dialogRef = this.dialog.open(EditorCreatorComponent, {
  //     minWidth: '420px',
  //     data: {}
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result && result !== 'cancel') {
  //       if (
  //         this.tabDefs[this.currentTabIndex].attributes.findIndex(
  //           (a: any) => a.attributeName === result.attributeName
  //         ) >= 0
  //       ) {
  //         this.modal.show(
  //           ModalType.error,
  //           'key_error',
  //           'l10n_attributeAlreadyExists'
  //         );
  //       } else {
  //         this.tabDefs[this.currentTabIndex].attributes.push({
  //           attributeName: result.attributeName,
  //           editorType: result.type,
  //           editorConfig: {
  //             attributeName: result.attributeName,
  //             units: result.layoutUnits
  //           }
  //         });
  //       }
  //     }
  //   });
  // }

  // onChangeColumnNumber() {
  //   switch (this.tabDefs[this.currentTabIndex].columnNumber) {
  //     case 1:
  //       this.tabDefs[this.currentTabIndex].columnNumber = 2;
  //       break;
  //     case 2:
  //       this.tabDefs[this.currentTabIndex].columnNumber = 3;
  //       break;
  //     case 3:
  //       //   this.tabDefs[this.currentTabIndex].columnNumber = 4;
  //       //   break;
  //       // case 4:
  //       this.tabDefs[this.currentTabIndex].columnNumber = 1;
  //       break;
  //     default:
  //       break;
  //   }
  // }

  onDeleteTab(tabDef: any) {
    const index = this.tabDefs.findIndex((t) => t.name === tabDef.name);
    if (index >= 0) {
      this.tabDefs.splice(index, 1);
    }
  }

  onSave() {
    setTimeout(() => {
      if (!this.hasError() && !this.saveDisabled) {
        if (this.advancedView) {
          this.swap.broadcast({
            name: 'save-attribute',
            parameter: '!advancedView!',
          });
        } else {
          const tabName = this.tabDefsToShow[this.currentTabIndex].name;
          this.swap.broadcast({
            name: this.swap.EVENT_ATTRIBUTE_VIEW_BEFORE_SAVE,
            parameter: tabName,
          });
        }
      }
    }, this.config.getConfig('intervalTiny', 100));
  }

  onToggleSave() {
    if (this.previewButton && this.previewButton.isOpen) {
      this.previewButton.toggle(false);
    }
    this.onSave();
  }

  onPreview() {
    this.previewMode = true;
    this.swap.broadcast({
      name: 'save-attribute',
      parameter: '!preview!',
    });
  }

  onExitPreview(withSave = false, refresh = true) {
    if (this.resourcePreview) {
      this.subscription.add(
        this.resourcePreview
          .stop()
          .pipe(
            tap(() => {
              this.previewEvent = undefined;
              this.previewMode = false;
              if (withSave) {
                this.onSave();
              } else if (refresh) {
                this.onRefresh(null);
              }
            })
          )
          .subscribe()
      );
    }
  }

  onRefresh(e: Event) {
    if (e) {
      if (this.isTabDirty() && !this.configMode) {
        const confirm = this.modal.show(
          ModalType.confirm,
          'key_confirmation',
          'l10n_changesNotSaved'
        );
        this.subscription.add(
          confirm.afterClosed().subscribe((result) => {
            if (result && result === 'yes') {
              this.refreshComponent();
            } else {
              e.stopPropagation();
            }
          })
        );
      } else {
        this.refreshComponent();
      }
    } else {
      this.refreshComponent();
    }
  }

  onSwitchView(e: Event) {
    if (e) {
      if (this.isTabDirty() && !this.configMode) {
        const confirm = this.modal.show(
          ModalType.confirm,
          'key_confirmation',
          'l10n_changesNotSaved'
        );
        this.subscription.add(
          confirm.afterClosed().subscribe((result) => {
            if (result && result === 'yes') {
              this.switchView();
            } else {
              e.stopPropagation();
            }
          })
        );
      } else {
        this.switchView();
      }
    } else {
      this.switchView();
    }
  }

  onExportTab(tabName: string) {
    const popupConfig: PopupConfig = new PopupConfig();
    popupConfig.title = 'key_export';
    popupConfig.style = 'outline';
    popupConfig.singleButton = true;
    popupConfig.confirmButton = new PopupButtonController(
      'key_export',
      'primary'
    );
    popupConfig.data = {
      name: {
        text: 'key_name',
        value: tabName,
        type: 'text',
        required: true,
        focused: true,
        validation: '^[a-z0-9]*$',
      },
    };

    this.subscription.add(
      this.modal
        .popup(popupConfig)
        .pipe(
          tap((windowResult: any) => {
            if (!(windowResult instanceof WindowCloseResult)) {
              const viewSetting =
                this.resource.primaryViewSetting.editingView[this.formName];
              if (viewSetting.sections && viewSetting.sections.length > 0) {
                const pos = viewSetting.sections.findIndex(
                  (s: any) => s.name === tabName
                );
                if (pos >= 0) {
                  const objExport = {
                    editingView: {},
                  };
                  objExport.editingView[this.formName] = {};
                  objExport.editingView[this.formName].sections = [];
                  const item = this.utils.DeepCopy(viewSetting.sections[pos]);
                  item.name = windowResult.name.value;
                  objExport.editingView[this.formName].sections.push(item);

                  this.clipboard.copy(
                    this.utils.stringifyComponentConfig(objExport)
                  );
                }
              }
            }
          })
        )
        .subscribe()
    );
  }

  onImportTab() {
    const popupConfig: PopupConfig = new PopupConfig();
    popupConfig.title = 'key_import';
    popupConfig.style = 'outline';
    popupConfig.data = {
      name: {
        text: 'key_name',
        value: undefined,
        type: 'text',
        required: false,
        focused: false,
        validation: '^[a-z0-9]*$',
      },
      settings: {
        text: 'key_settings',
        value: '',
        type: 'textarea',
        rows: 6,
        required: true,
        focused: true,
      },
    };

    let settingsToImport: any;
    let formName = '';
    let keyName = '';
    let customName = '';
    let keyPos = -1;
    let process: MatDialogRef<ModalComponent, any>;

    this.subscription.add(
      this.modal
        .popup(popupConfig)
        .pipe(
          switchMap((windowResult: any) => {
            if (!(windowResult instanceof WindowCloseResult)) {
              if (
                windowResult &&
                windowResult.settings &&
                windowResult.settings.value
              ) {
                settingsToImport = JSON.parse(windowResult.settings.value);
                if (settingsToImport.editingView) {
                  if (Object.keys(settingsToImport.editingView).length === 1) {
                    formName = Object.keys(settingsToImport.editingView)[0];
                    if (
                      settingsToImport.editingView[formName].sections &&
                      settingsToImport.editingView[formName].sections.length ===
                        1
                    ) {
                      keyName =
                        settingsToImport.editingView[formName].sections[0].name;
                      customName = windowResult.name.value ?? keyName;

                      let message = '';

                      if (formName !== this.formName) {
                        message += `<li>${this.translate.instant(
                          'key_differentFormName'
                        )}</li>`;
                      }

                      keyPos = this.resource.primaryViewSetting.editingView[
                        this.formName
                      ].sections.findIndex(
                        (s: any) =>
                          s.name.toLowerCase() === customName.toLowerCase()
                      );
                      if (keyPos >= 0) {
                        message += `<li>${this.translate.instant(
                          'key_sameTabName'
                        )}: ${customName}</li>`;
                      }

                      if (message) {
                        message = `<ul>${message}</ul>${this.translate.instant(
                          'key_confirmImportSettings'
                        )}`;
                        return this.modal
                          .show(ModalType.confirm, 'key_confirmation', message)
                          .afterClosed();
                      } else {
                        return of('yes');
                      }
                    }
                  }
                }

                return throwError({
                  message: this.translate.instant('key_invalidSetting'),
                });
              } else {
                this.modal.show(
                  ModalType.error,
                  'key_error',
                  'key_invalidSetting'
                );
              }
            }
            return EMPTY;
          }),
          switchMap((dialogResult: any) => {
            if (dialogResult && dialogResult === 'yes') {
              if (keyPos >= 0) {
                this.resource.primaryViewSetting.editingView[
                  this.formName
                ].sections[keyPos] = {
                  ...settingsToImport.editingView[formName].sections[0],
                  name: customName,
                };
              } else {
                this.resource.primaryViewSetting.editingView[
                  this.formName
                ].sections.push({
                  ...settingsToImport.editingView[formName].sections[0],
                  name: customName,
                });
              }
              // process = this.modal.show(
              //   ModalType.progress,
              //   'key_savingChanges',
              //   '',
              //   '300px'
              // );
              // return this.resource.updateUISettings();
            }
            return EMPTY;
          }),
          tap((updateResult: string) => {
            if (updateResult === 'expired') {
              this.modal.show(
                ModalType.error,
                'key_error',
                'key_uiRefreshNeeded'
              );
            } else {
              console.log('done');
              // this.initWizards();
              // this.selectedWizard = this.availableWizards.find(
              //   (w) => w.name.toLowerCase() === customName.toLowerCase()
              // );
              // this.onWizardSelectionChange();
            }
          }),
          catchError((error: any) => {
            this.modal.show(
              ModalType.error,
              'key_error',
              this.utils.getServiceError(error)
            );
            return EMPTY;
          }),
          finalize(() => {
            if (process) {
              process.close();
            }
          })
        )
        .subscribe()
    );
  }
}
